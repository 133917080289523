export default () => [
  // base propbar
  {
    from: new Date('03 February 2025 00:00:00 UTC'),
    content: {
      text: '',
      linkText: 'Klarna available at checkout',
      link: '/help/payments-and-ordering',
      colour: 'black',
      icon: 'klarna',
    },
  },
  // February 20% off everything
  {
    from: new Date('12 February 2025 08:00:00 UTC'),
    content: {
      text: '20% off everything. T&Cs apply. ',
      linkText: 'Shop here',
      link: '/list/shop-all-clothing?tag=tu:propbar-promo',
      colour: 'black',
    },
  },
  // base propbar
  {
    from: new Date('18 February 2025 23:59:00 UTC'),
    content: {
      text: '',
      linkText: 'Klarna available at checkout',
      link: '/help/payments-and-ordering',
      colour: 'black',
      icon: 'klarna',
    },
  },
]
